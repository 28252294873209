import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="main-footer">
      <span>&copy; 2023. First Global FX. All Rights Reserved.</span>
      <span>
        Created by:{" "}
        <Link to="#" target="_blank">
          PM Labs{" "}
        </Link>
      </span>
    </div>
  );
}
