import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Placeholder,
  Row,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import apiService from "../../service/api.service";
import { errorNotification, successNotification } from "../../utils/notification";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import useCategoryData from "../../store/CategoryStore";

export default function WaitingNewEditModal({
  data,
  closeModal,
  setRenewData,
}) {

  const [selectOptions, setSelectOptions] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [apiImageUrl, setApiImageUrl] = useState(data?.img_url);
  const [loading, setLoading] = useState(false);
  const [isBanner, setIsBanner] = useState(data?.show_banner);
  const [sendNotification, setSendNotification] = useState(false);
  const [busyModal, setBusyModal] = useState(false);

  const categories = useCategoryData((state) => state.selectedData);


  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePhotoSubmit = async (event) => {
    event.preventDefault();
    if (selectedFile) {
      setLoading(true);
      const formData = new FormData();
      formData.append("image", selectedFile);
      try {
        const response = await apiService.uploadImage("upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        successNotification();
        setPreviewImage(null);
        setApiImageUrl(response.data.image_url);
      } catch (error) {
        errorNotification(
          `\nDosya yüklenirken bir hata oluştu.\n${error.toString()}`
        );
      }
      setLoading(false);
    } else {
      errorNotification("\nLütfen görsel seçiniz.");
    }
  };

  const handleDeleteImage = async (event) => {
    event.preventDefault();
    if (!apiImageUrl.includes("firstg.net")) {
      successNotification();
      setPreviewImage(null);
      setApiImageUrl(null);
      return;
    }
    if (apiImageUrl) {
      setLoading(true);
      console.log(typeof apiImageUrl.split("/").pop());
      try {
        await apiService.delete("upload/delete", {
          path: apiImageUrl.split("/").pop(),
        });
        successNotification();
        setPreviewImage(null);
        setApiImageUrl(null);
      } catch (error) {
        errorNotification(
          `\nGörsel silinirken bir hata oluştu.\n${error.toString()}`
        );
      }
      setLoading(false);
    } else {
      errorNotification("\nLütfen görsel seçiniz.");
    }
  };

  const [formValues, setFormValues] = useState({
    title: data.title,
    category: data.category_id,
    content: data.description,
    tags: data.tags,
    summary: data.meta_desc,
    source_url: data.source_url
  });

  const handleSubmit = async () => {
    console.log(formValues);
    const emptyFields = [];
    if (formValues.title === "") {
      emptyFields.push("Haber Başlığı");
    }
    if (formValues.category === "") {
      emptyFields.push("Kategori");
    }
    if (formValues.summary === "") {
      emptyFields.push("Özet");
    }
    if (formValues.content === "") {
      emptyFields.push("Açıklama");
    }
    if (formValues.category === null) {
      emptyFields.push("Kategori");
    }
    if (apiImageUrl === null) {
      emptyFields.push("Öne çıkan görsel");
    }
    // Diğer alanlar için de kontrolü yapabilirsiniz

    if (emptyFields.length > 0) {
      errorNotification(
        `\n${emptyFields.join("\n")},\n alanlarını doldurunuz.`
      );
    } else {
      try {
        setBusyModal(true);
        const generatedData = {
          category_id: formValues.category.value,
          title: formValues.title,
          meta_desc: formValues.summary,
          description: formValues.content,
          img_url: apiImageUrl,
          tags: formValues.tags,
          show_banner: isBanner,
          notificate: sendNotification
        };

        await new Promise((resolve) => setTimeout(resolve, 2000));
        await apiService.put("news/publish-by-api", data.id, generatedData);
        setBusyModal(false);
        successNotification();
        closeModal(false);
        setRenewData(true);
      } catch (error) {
        errorNotification(`\nBir hata oluştu.\n${error.toString()}`);
      } finally {
        setBusyModal(false);
      }
    }
  };


  useEffect(() => {

    setSelectOptions(
      categories.map((category) => {
        return {
          value: category.id,
          label: category.title,
        };
      })
    );
  }, []);

  useEffect(() => {
    setApiImageUrl(apiImageUrl);
  }, [apiImageUrl]);

  return (
    <Modal
      show={true}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
      size="lg"
      centered
    >
      <div style={{ position: "relative" }}>
        {busyModal && (
          <Spinner
            animation="border"
            variant="primary"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              filter: "blur(0px)",
              zIndex: 22,
            }}
          />
        )}

        <form
          onSubmit={(e) => e.preventDefault}
          style={{
            filter: busyModal ? "blur(3px)" : "",
            animation: busyModal ? "0.2s all" : "",
            zIndex: busyModal ? 2 : "",
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: "1rem" }}>
              {data.title} | Yayına Al
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {previewImage === null && !loading && !apiImageUrl && (
                <>
                  <Col md={6} className="mb-3">
                    <Card>
                      <Card.Body>
                        <Card.Title>
                          <Placeholder xs="6" animation="wave"></Placeholder>
                        </Card.Title>
                        <div>
                          Lütfen görsel yükleyin!
                          <Placeholder as="div" animation="wave">
                            <Placeholder as="div" className="w-100" />
                            <Placeholder
                              bg="danger"
                              as="div"
                              className="w-100"
                            />
                            <Placeholder as="div" className="w-100" />
                            <Placeholder
                              bg="danger"
                              as="div"
                              className="w-100"
                            />
                            <Placeholder as="div" className="w-100" />
                          </Placeholder>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col
                    md={6}
                    style={{ backgroundColor: "#fafafa" }}
                    className="p-4"
                  >
                    <div>
                      <input type="file" onChange={handleFileChange} />
                    </div>
                    <div className="w-100 mt-3">
                      <Button onClick={handlePhotoSubmit}>Görseli Ekle</Button>
                    </div>
                  </Col>
                </>
              )}

              {previewImage && !apiImageUrl && !loading && (
                <>
                  <Col md={6} className="mb-3">
                    <img
                      src={previewImage}
                      alt="Önizleme"
                      className=""
                      style={{ width: "100%", height: "150px" }}
                    />
                  </Col>
                  <Col
                    md={6}
                    style={{ backgroundColor: "#fafafa" }}
                    className="p-4"
                  >
                    <div>
                      <input type="file" onChange={handleFileChange} />
                    </div>
                    <div className="w-100 mt-3">
                      <Button onClick={handlePhotoSubmit}>Görseli Ekle</Button>
                    </div>
                  </Col>
                </>
              )}

              {loading ? (
                <Col md={6} className="mb-3">
                  <div style={{ position: "relative" }}>
                    <Placeholder as="div" animation="wave">
                      <img
                        src={previewImage}
                        alt="Önizleme"
                        className=""
                        style={{ width: "100%", height: "150px" }}
                      />
                    </Placeholder>
                  </div>
                </Col>
              ) : (
                apiImageUrl && (
                  <>
                    <Col md={6} className="mb-3">
                      <img
                        src={apiImageUrl}
                        alt="Önizleme"
                        className=""
                        style={{ width: "100%", height: "150px" }}
                      />
                    </Col>
                    <Col md={6} className="p-3">
                      <Alert variant="success">
                        Görsel başarıyla yüklendi <br />
                        <Alert.Link href={apiImageUrl} target="_blank">
                          Önizleme için tıklayın.
                        </Alert.Link>
                      </Alert>
                      <Button
                        variant="danger"
                        onClick={(event) => {
                          handleDeleteImage(event);
                        }}
                      >
                        Görseli Sil
                      </Button>
                    </Col>
                  </>
                )
              )}

              <Col md={8}>
                <div className="mb-3">
                  <Form.Label>Haber Başlığı</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Haber Başlığı"
                    value={formValues.title}
                    onChange={(e) =>
                      setFormValues({ ...formValues, title: e.target.value })
                    }
                  />
                </div>
              </Col>
              <Col md={4}>
                <div className="mb-3">
                  <Form.Label>Kategori</Form.Label>
                  <Select
                    options={selectOptions}
                    placeholder="Kategori Seçiniz"
                    value={selectOptions.find(
                      (option) => option.value === formValues.category
                    )}
                    onChange={(selectedOption) =>
                      setFormValues({ ...formValues, category: selectedOption })
                    }
                  />
                </div>
              </Col>

              <Col md={12}>
                <div className="mb-3">
                  <Form.Label>Etiketler</Form.Label>
                  <Tags
                    className="w-100"
                    placeholder="Alakalı etiketleri giriniz."
                    defaultValue={formValues.tags}
                    onChange={(values) => {
                      let tags = values.detail.tagify
                        .getCleanValue()
                        .map((value) => Object.values(value).toString());
                      setFormValues((formValues) => ({
                        ...formValues,
                        tags: tags.join(","),
                      }));
                    }}
                  />
                </div>
              </Col>

              <Col md={12}>
                <div className="mb-3">
                  <Form.Label htmlFor="exampleFormControlTextarea1">
                    İçerik
                  </Form.Label>
                  <ReactQuill
                    theme="snow"
                    value={formValues.content}
                    onChange={(e) =>
                      setFormValues({ ...formValues, content: e })
                    }
                  />
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Form.Label htmlFor="summary">Özet</Form.Label>
                  <Form.Control
                    as="textarea"
                    id="summary"
                    rows="2"
                    placeholder="Özet giriniz"
                    value={formValues.summary}
                    onChange={(e) =>
                      setFormValues({ ...formValues, summary: e.target.value })
                    }
                  ></Form.Control>
                </div>
              </Col>
              <Col md={12}>
                <div className="mb-3">
                  <Form.Label>Kaynak (Varsa Belirtin)</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Haber Kaynağı"
                    value={formValues.source_url}
                    onChange={(e) =>
                      setFormValues({ ...formValues, source_url: e.target.value })
                    }
                  />
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <div>
              <Form.Check
                type="switch"
                checked={isBanner}
                onChange={(e) => {
                  setIsBanner(e.target.checked);
                }}
                color="success"
                label="Banner'da Göster"
              />
              <Form.Check
                type="switch"
                checked={sendNotification}
                onChange={(e) => {
                  setSendNotification(e.target.checked);
                }}
                label="Bildirim Yolla"
              />
            </div>
            <div>
              {apiImageUrl ? (
                <Button variant="success" onClick={handleSubmit}>
                  Yayına Al
                </Button>
              ) : (
                <Button variant="warning" disabled={true}>
                  Yayınla
                </Button>
              )}
            </div>
          </Modal.Footer>
        </form>
      </div>
    </Modal>
  );
}
